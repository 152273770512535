import { gsap } from 'gsap'
import { animationSecondStarter } from '../app'
import { scaleFMA } from '../functions/fma'
import { scaleIllus } from '../functions/illus'

const blocIllus = document.getElementById('illus')
const bigFMA = document.querySelector('.fma-animation')

export const loadingTimeline = gsap.timeline()
loadingTimeline.pause()

loadingTimeline.to('.FMA', { duration: 1.5, opacity: '0' }, '#first')
loadingTimeline.to('.FMA', { duration: 1.5, width: '0' }, '#first')
loadingTimeline.to('.FMA', { duration: 1.5, scale: '0' }, '#first')
loadingTimeline.call(scaleFMA, [bigFMA], '#second')
loadingTimeline.fromTo(
  '.fma-subtitle',
  {
    transform: 'translateX(-50%) scale(0.8',
    opacity: 0,
  },
  {
    transform: 'translateX(-50%) scale(1)',
    duration: 2,
    opacity: 1,
  },
  '#second'
)
loadingTimeline.to(
  '.fma-coq-head',
  { duration: 2.5, opacity: 1, zIndex: 1 },
  '#second'
)
loadingTimeline.call(() => {
  animationSecondStarter.children[0].classList.add('heartbeat')
}, [])
loadingTimeline.to('.FMA', { duration: 2, scaleY: '5' }, '#fade+=2.5')
loadingTimeline.to(
  '.fma-big-title',
  { duration: 2, opacity: '0' },
  '#fade+=2.5'
)

loadingTimeline.call(scaleIllus, [blocIllus], '#fade+=2.5')
loadingTimeline.to(blocIllus, { opacity: 1 }) // a commenter pour garder illus
loadingTimeline.to(
  '#logo-box',
  {
    duration: 0.4,
    transform: 'translateX(0)',
    ease: 'ease-out',
  },
  '#end'
)
loadingTimeline.call(
  () => {
    const bloc = document.querySelector('.join-box')
    bloc.classList.add('display')
  },
  [],
  '#end'
)

loadingTimeline.to('.box-fuck-macron', {
  duration: 1,
  display: 'inline-block',
  opacity: 1,
})
