/* eslint-disable no-unused-vars */
let intervalId
let percent = 0

export const scaleIllus = (illus) => {
  console.log('start scale illus')
  if (!intervalId) {
    intervalId = setInterval(() => {
      if (percent <= 100) {
        percent += 1
        illus.style.opacity = 1
        illus.style.zIndex = 2
        illus.style.width = `${percent}%`
        illus.style.height = `${percent}%`
      } else {
        clear()
        console.log('stop scale illus')
      }
    }, 20)
  }
}

function clear() {
  clearInterval(intervalId)
}
