import { gsap } from 'gsap'

const blocIllus = document.getElementById('illus')

export const contentTimeline = gsap.timeline()
contentTimeline.pause()

contentTimeline.call(() => {
  const bloc = document.querySelector('.join-box')
  bloc.classList.remove('display')
  blocIllus.classList.add('move')
}, [])

contentTimeline.call(() => {
  const star = document.querySelector('.box-star')
  star.classList.add('display')
}, [])
contentTimeline.call(() => {
  const join = document.querySelector('.join-us')
  join.classList.add('display')
}, [])
// contentTimeline.to('.box-star', {
//   duration: 4,
//   ease: 'ease-in-out',
//   top: '64%',
//   width: '90%',
//   left: '17%',
// })
// contentTimeline.to(
//   '#illus',
//   {
//     paddingTop: '50%',
//     duration: 1,
//   },
//   '#first'
// )
contentTimeline.to('.container-fuck-macron', {
  opacity: 1,
  duration: 1,
  zIndex: 9,
})
contentTimeline.to('.second-join', {
  duration: 1,
  display: 'block',
})
contentTimeline.to(
  '.second-join',
  {
    opacity: 1,
    transform: 'translateX(0)',
    stagger: 0.5,
  },
  '-=0.5'
)

// contentTimeline.to(
//   '.join-box',
//   {
//     duration: '0.7',
//     opacity: '1',
//   },
//   '#opacity'
// )
// contentTimeline.fromTo(
//   '.join-box',
//   {
//     top: '25%',
//   },
//   {
//     duration: 0.5,
//     top: '20%',
//   },
//   '#opacity'
// )
