import { contentTimeline } from './timelines/contentTimeline'
import { loadingTimeline } from './timelines/loadingTimeline'
export const boxJoinUs = document.querySelector('.btn-join')
export const animationStarter = document.getElementById('animation-starter')
export const animationSecondStarter = document.querySelector('.fma-coq-head')

// loadingTimeline.play(6)

animationStarter.addEventListener('click', () => {
  animationStarter.classList.remove('heartbeat')
  animationStarter.style.display = 'none'
  loadingTimeline.play()
  loadingTimeline.addPause(6)
})
animationSecondStarter.addEventListener('click', () => {
  animationSecondStarter.children[0].classList.remove('heartbeat')
  loadingTimeline.play()
  loadingTimeline.addPause(6)
})
boxJoinUs.addEventListener('click', () => {
  contentTimeline.play()
})
